interface IdleTimeoutOptions {
   delay?: number;
   pollDelay?: number;
   keepAliveEvents?: string[];
}

class IdleTimeout {
   private _options: IdleTimeoutOptions = {
      delay: 1000 * 60 * 10,
      pollDelay: 1000 * 60,
      keepAliveEvents: ['keydown', 'mousedown', 'mousemove', 'touchstart', 'touchend', 'wheel']
   }
   private _done = true;
   private _element: HTMLElement;
   private _startTime: number;
   private _timer: number;

   private _resetPoll = () => {
      clearTimeout(this._timer);
      const timeLeft = this.delay - this.duration;
      const pollDelay = this.pollDelay > timeLeft ? timeLeft : this.pollDelay;

      this._timer = window.setTimeout(this._pollHandler, pollDelay);
   };

   private _pollHandler = () => {
      this._dispatchEvent('idletimeout-poll');
      if (this.duration < this.delay) {
         this._resetPoll();
         return;
      }

      this._dispatchEvent('idletimeout-expire');
      this.cancel();
   };

   private _eventHandler = (event) => {
      if (this._done) { return; }
      if (event.type === 'message' && event.data !== 'idletimeout-reset') {
         return;
      }

      this.resetTimer();
   }

   private _initEventListeners() {
      this._options.keepAliveEvents.forEach((eventName: string) => {
         this._element.addEventListener(eventName, this._eventHandler);
      });
      
      window.addEventListener('message', this._eventHandler);
   }

   private _destroyEventListeners() {
      this._options.keepAliveEvents.forEach((eventName: string) => {
         this._element.removeEventListener(eventName, this._eventHandler);
      });

      window.removeEventListener('message', this._eventHandler);
   }

   private _dispatchEvent(eventName: string): void {
      const event = new CustomEvent(eventName, { bubbles: true, cancelable: true, detail: this });
      this._element.dispatchEvent(event);
   }

   constructor(element: HTMLElement, options?: IdleTimeoutOptions) {
      this._element = element;
      this._options = Object.assign({}, this._options, options);
      this.resetTimer();
   }

   public resetTimer() {
      if (this._done) {
         this._done = false;
         this._initEventListeners();
      }

      this._dispatchEvent('idletimeout-start');
      this._startTime = Date.now();
      this._resetPoll();
   }
   
   public cancel() {
      this._done = true;
      clearTimeout(this._timer);
      this._destroyEventListeners();
   }

   public get element() {
      return this._element;
   }

   public get duration() {
      return Date.now() - this._startTime;
   }

   public get delay() {
      return this._options.delay;
   }

   public get pollDelay() {
      return this._options.pollDelay;
   }

   public get startTime() {
      return this._startTime;
   }
}
